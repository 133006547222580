import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import whatIs from '../assets/img/what-is.svg'
import heist from '../assets/img/heist.gif'

const BioImage = styled.img`
    height: 250px;
    border-radius: 10px;

    @media screen and (max-width: 768px){
        height: 175px;
    }
`

const BioBody = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #ffffff;
  overflow-wrap: break-word;
  display: flex;
  justify-content: left;
  margin-top: 1rem;

  @media screen and (max-width: 768px){
    text-align: center;
    justify-content: center !important;
  }
`

const ShitposterTitle = styled.img`
  vertical-align: middle;
  width: 450px;
  margin-top: 30%;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px){
    max-width: 325px;
  }
`

const BioContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 10px 10px; 
  grid-template-areas: 
    ". ."; 

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    grid-template-areas: 
    ".";
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
`

const FlexContainer = styled.div`
  // display: flex;
  // justify-content: space-between;
  // margin-bottom: 2rem;
`


const AboutContainer = styled.div`
  margin-top: 30%;
  margin-bottom: 5rem;
  
  @media screen and (max-width: 1920px){
    margin-top: 10%;
  }

  @media screen and (max-width: 768px){
    margin-top: 1rem !important;
}
`

const Button = styled.a`
    display: inline-block !important;
    margin-top: 2rem;
    transition: all 0.15s ease-in-out;
    background-color: rgb(255,255,255,0.5);
    color: #ffffff;
    font-family: Jost;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 30px;
    border-radius: 30px;
    cursor: pointer;
    max-width: 1000px;
    display: flex;
    text-decoration: none;
    text-align: center;

    &:hover {
      background-color: rgb(255,255,255,0.7);
    }

    @media screen and (max-width: 768px){
        display: block !important;
    }
`

function About() {

  return (
    <>
      <AboutContainer>
        <FlexContainer>
            <ShitposterTitle src={whatIs} alt="Money Maxi Logo" draggable="false"/>
        </FlexContainer>
        <BioContainer>
            <BioImage src={heist} draggable="false"/>
        </BioContainer>
        <FlexContainer>
            <BioBody>Money Maxis is a group of bright and experienced minds within the crypto and NFT ecosystem. We encourage people to join our community to learn, network and grow alongside other versed people within the space.</BioBody>
        </FlexContainer>
        <FlexContainer>
            <BioBody>Our group was formed as a bunch of friends, and we intend to keep it that way. Everyone in Money Maxis is treated as an equal — no using each other as exit liquidity.</BioBody>
        </FlexContainer>
        <FlexContainer>
            <BioBody>Each week, we auction two of one hundred Money Maxis, welcoming new frens to the family.</BioBody>
        </FlexContainer>
        <Button href="https://discord.gg/maxis" target="blank_">Visit our Discord</Button>
      </AboutContainer>
    </>
  )
}

export default About