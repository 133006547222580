import React from 'react'
import { useState, useEffect } from 'react'
import {isMobile} from 'react-device-detect'
import styled from 'styled-components'
import shitposter from '../assets/img/shitposter-v2.svg'
import theKing from '../assets/img/the-king.gif'
import theKingStill from '../assets/img/the-king-still.png'
import thePrince from '../assets/img/the-prince.gif'
import thePrinceStill from '../assets/img/the-prince-still.png'
import theTinkerer from '../assets/img/the-tinkerer.gif'
import theTinkererStill from '../assets/img/the-tinkerer-still.png'
import theApe from '../assets/img/the-ape.gif'
import theApeStill from '../assets/img/the-ape-still.png'
import theCompanion from '../assets/img/the-companion.gif'
import theCompanionStill from '../assets/img/the-companion-still.png'
import theWolf from '../assets/img/the-wolf.gif'
import theWolfStill from '../assets/img/the-wolf-still.png'
import theShark from '../assets/img/the-shark.gif'
import theSharkStill from '../assets/img/the-shark-still.png'
import theFlash from '../assets/img/the-flash.gif'
import theFlashStill from '../assets/img/the-flash-still.png'
import theWizard from '../assets/img/the-wizard.gif'
import theWizardStill from '../assets/img/the-wizard-still.png'
import theImpaler from '../assets/img/the-impaler.gif'
import theImpalerStill from '../assets/img/the-impaler-still.png'
import theProtector from '../assets/img/the-protector.gif'
import theProtectorStill from '../assets/img/the-protector-still.png'
import theMobster from '../assets/img/the-mobster.gif'
import theMobsterStill from '../assets/img/the-mobster-still.png'
import theBlockchain from '../assets/img/the-blockchain.gif'
import theBlockchainStill from '../assets/img/the-blockchain-still.png'
import theDragon from '../assets/img/the-dragon.gif'
import theDragonStill from '../assets/img/the-dragon-still.png'
import theCapitalist from '../assets/img/the-capitalist.gif'
import theCapitalistStill from '../assets/img/the-capitalist-still.png'
import theOfficer from '../assets/img/the-officer.gif'
import theOfficerStill from '../assets/img/the-officer-still.png'
import theLeprechaun from '../assets/img/the-leprechaun.gif'
import theLeprechaunStill from '../assets/img/the-leprechaun-still.png'
import theMonster from '../assets/img/the-monster.gif'
import theMonsterStill from '../assets/img/the-monster-still.png'
import theJoker from '../assets/img/the-joker.gif'
import theJokerStill from '../assets/img/the-joker-still.png'
import thePirate from '../assets/img/the-pirate.gif'
import thePirateStill from '../assets/img/the-pirate-still.png'
import theEngineer from '../assets/img/the-engineer.gif'
import theEngineerStill from '../assets/img/the-engineer-still.png'
import theReaper from '../assets/img/the-reaper.gif'
import theReaperStill from '../assets/img/the-reaper-still.png'
import theSanitizer from '../assets/img/the-sanitizer.gif'
import theSanitizerStill from '../assets/img/the-sanitizer-still.png'
import theGenie from '../assets/img/the-genie.gif'
import theGenieStill from '../assets/img/the-genie-still.png'
import theBunny from '../assets/img/the-bunny.gif'
import theBunnyStill from '../assets/img/the-bunny-still.png'
import theRasta from '../assets/img/the-rasta.gif'
import theRastaStill from '../assets/img/the-rasta-still.png'
import theRestaurateur from '../assets/img/the-restaurateur.gif'
import theRestaurateurStill from '../assets/img/the-restaurateur-still.png'
import theAstronaut from '../assets/img/the-astronaut.gif'
import theAstronautStill from '../assets/img/the-astronaut-still.png'
import theSpendthrift from '../assets/img/the-spendthrift.gif'
import theSpendthriftStill from '../assets/img/the-spendthrift-still.png'
import theButcher from '../assets/img/the-butcher.gif'
import theButcherStill from '../assets/img/the-butcher-still.png'
import theGolfer from '../assets/img/the-golfer.gif'
import theGolferStill from '../assets/img/the-golfer-still.png'
import theMiner from '../assets/img/the-miner.gif'
import theMinerStill from '../assets/img/the-miner-still.png'
import theChef from '../assets/img/the-chef.gif'
import theChefStill from '../assets/img/the-chef-still.png'
import thePimp from '../assets/img/the-pimp.gif'
import thePimpStill from '../assets/img/the-pimp-still.png'
import theChemist from '../assets/img/the-chemist.gif'
import theChemistStill from '../assets/img/the-chemist-still.png'
import theBear from '../assets/img/the-bear.gif'
import theBearStill from '../assets/img/the-bear-still.png'
import theSurfer from '../assets/img/the-surfer.gif'
import theSurferStill from '../assets/img/the-surfer-still.png'
import theFisherman from '../assets/img/the-fisherman.gif'
import theFishermanStill from '../assets/img/the-fisherman-still.png'
import theStriker from '../assets/img/the-striker.gif'
import theStrikerStill from '../assets/img/the-striker-still.png'
import thePainter from '../assets/img/the-painter.gif'
import thePainterStill from '../assets/img/the-painter-still.png'
import thePlumber from '../assets/img/the-plumber.gif'
import thePlumberStill from '../assets/img/the-plumber-still.png'
import theNinja from '../assets/img/the-ninja.gif'
import theNinjaStill from '../assets/img/the-ninja-still.png'
import theAuctioneer from '../assets/img/the-auctioneer.gif'
import theAuctioneerStill from '../assets/img/the-auctioneer-still.png'
import thePeanut from '../assets/img/the-peanut.gif'
import thePeanutStill from '../assets/img/the-peanut-still.png'
import theHooper from '../assets/img/the-hooper.gif'
import theHooperStill from '../assets/img/the-hooper-still.png'
import theTurtle from '../assets/img/the-turtle.gif'
import theTurtleStill from '../assets/img/the-turtle-still.png'
import theStatue from '../assets/img/the-statue.gif'
import theStatueStill from '../assets/img/the-statue-still.png'
import theRedditor from '../assets/img/the-redditor.gif'
import theRedditorStill from '../assets/img/the-redditor-still.png'
import theFrog from '../assets/img/the-frog.gif'
import theFrogStill from '../assets/img/the-frog-still.png'
import theRanger from '../assets/img/the-ranger.gif'
import theRangerStill from '../assets/img/the-ranger-still.png'
import theMinion from '../assets/img/the-minion.gif'
import theMinionStill from '../assets/img/the-minion-still.png'
import theGojira from '../assets/img/the-gojira.gif'
import theGojiraStill from '../assets/img/the-gojira-still.png'
import theSponge from '../assets/img/the-sponge.gif'
import theSpongeStill from '../assets/img/the-sponge-still.png'
import theNotorious from '../assets/img/the-notorious.gif'
import theNotoriousStill from '../assets/img/the-notorious-still.png'
import theHacker from '../assets/img/the-hacker.gif'
import theHackerStill from '../assets/img/the-hacker-still.png'
import thePharaoh from '../assets/img/the-pharaoh.gif'
import thePharaohStill from '../assets/img/the-pharaoh-still.png'
import theSaiyan from '../assets/img/the-saiyan.gif'
import theSaiyanStill from '../assets/img/the-saiyan-still.png'
import theFrozen from '../assets/img/the-frozen.gif'
import theFrozenStill from '../assets/img/the-frozen-still.png'
import theWaiter from '../assets/img/the-waiter.gif'
import theWaiterStill from '../assets/img/the-waiter-still.png'
import theBatman from '../assets/img/the-batman.gif'
import theBatmanStill from '../assets/img/the-batman-still.png'
import theBrain from '../assets/img/the-brain.gif'
import theBrainStill from '../assets/img/the-brain-still.png'
import theCowboy from '../assets/img/the-cowboy.gif'
import theCowboyStill from '../assets/img/the-cowboy-still.png'

const MaxiImage = styled.img`
  height: 100px;
  border-radius: 10px;
  opacity: 50%;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid rgb(255,255,255,.1);

  &:hover {
    opacity: 100%;
    transform: scale(103%);
    transform: scale(103%);
  }

  @media screen and (max-width: 768px){
    height: 70px;
  }
`

const BioImage = styled.img`
    height: 250px;
    border-radius: 10px;
`

const BioTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  display: flex;
  justify-content: right;

  @media screen and (max-width: 768px){
    text-align: center;
    justify-content: center !important;
  }
`

const BioSubtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin-top: 5px;
  display: flex;
  justify-content: right;
  color: rgb(255,255,255,0.7);

  @media screen and (max-width: 768px){
    text-align: center;
    justify-content: center !important;
  }
`

const BioBody = styled.div`
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  color: #ffffff;
  overflow-wrap: break-word;
  display: flex;
  justify-content: right;
  margin-top: 1rem;

  @media screen and (max-width: 768px){
    text-align: center;
    justify-content: center !important;
  }
`

const ShitposterTitle = styled.img`
  vertical-align: middle;
  width: 450px;
  margin-top: 30%;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px){
    max-width: 325px;
  }
`

const BioContainer = styled.div`
  display: grid;
  margin-top: 3rem;
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 10px 10px; 
  grid-template-areas: 
    ". ."; 

  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    grid-template-areas: 
    ".";
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
`

const FlexContainer = styled.div`
  // display: flex;
  // justify-content: space-between;
  // margin-bottom: 2rem;
`

const ImageGrid = styled.div`
  margin-top: 5rem;
  margin-bottom: 10rem;
  display: grid; 
  // justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  gap: 20px 15px; 
  grid-template-areas: 
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."
    ". . . . . ."; 

    
  @media screen and (max-width: 768px){
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap: 15px 0px;
    grid-template-areas: 
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . ."
      ". . . .";
      justify-items: center;
  }
`

const Badge = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 15px;
  background: #e3b027;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding: 4px 9px 4px 9px;
  margin-top: 1px;
  margin-left: 10px;
`

const MaxiBadge = styled(Badge)`
  background: #7bcf27;
`

const ExternalLink = styled.div`
  fill: #323232;
  padding-top: 1px;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    fill: #000000;
  }
`

const FlexRight = styled.div`
  display: flex;
  justify-content: right !important;

  @media screen and (max-width: 768px){
      justify-content: center !important;
  }
`

const SocialContainer = styled.a`
  height: 30px;
  width: 30px;
  background: rgb(255,255,255,0.7);
  border-radius 5px;
  margin-top: 15px;
  display: flex;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center !important;
`

const SpanGap = styled.span`
  width: 10px;
`

function Members() {

  const [selectedMaxi, setSelectedMaxi] = useState(theTinkerer)
  const [selectedTitle, setSelectedTitle] = useState("The Tinkerer")
  const [selectedSubtitle, setSelectedSubtitle] = useState("Jimmeh")
  const [selectedBody, setSelectedBody] = useState("Always lurking. Probably still online at 6am. Bri'ish. In his schizo arc. The developer behind Money Maxis.")
  const [isFounder, setFounder] = useState(true)
  const [maxiTwitter, setMaxiTwitter] = useState("https://twitter.com/jimmehjpg")
  const [maxiWebsite, setMaxiWebsite] = useState("http://cymbal.xyz")
  const [preload, setPreload] = useState(false)

  function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
  )}

  useEffect(() => {
    sleep(2000).then(()=>{
      preloader()
   })

  });

  function preloader() {
    if (preload == false) {
      const imagesPreload = [theKing, thePrince, theTinkerer, theApe, theCompanion, theWolf, theShark, theFlash, theWizard, theImpaler, theProtector,
      theMobster, theBlockchain, theDragon, theCapitalist, theOfficer, theLeprechaun, theMonster, theJoker, thePirate, theEngineer, theReaper, theSanitizer, theGenie,
      theBunny, theRasta, theRestaurateur, theSpendthrift, theAstronaut, theButcher, theMiner, theChef, thePimp, theChemist, theBear, theSurfer, theGolfer, theFisherman,
      theStriker, thePainter, thePlumber, theNinja, theAuctioneer, thePeanut, theHooper, theTurtle, theStatue, theRedditor, theFrog, theRanger, theMinion, theGojira, theSponge,
      theNotorious, theHacker, thePharaoh, theFrozen, theSaiyan, theWaiter, theBatman, theBrain, theCowboy];
      imagesPreload.forEach((image) => {
          const newImage = new Image();
          newImage.src = image;
          window[image] = newImage;
          console.log("Preloading: " + image)
      });
      setPreload(true)
    }
  }
  
  const playClick = () => {
    const clickSound = new Audio("https://maxi-site-git-dev-spongled.vercel.app/static/media/click-crop.2e3b7496c11e226d1eb3.mp3")
    clickSound.play()
    clickSound.volume = 0.35;
  }

  function selectTheKing(){
    setSelectedMaxi(theKing)
    setSelectedTitle("The King")
    setSelectedSubtitle("Alexander42")
    setSelectedBody("Posts 500 tweets per day. Top influencer of NFT Twitter. Will send half a message and disappear. Exhibits early signs of dementia.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/Shillin_Villian")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }

  }

  function selectThePrince(){
    setSelectedMaxi(thePrince)
    setSelectedTitle("The Prince")
    setSelectedSubtitle("bennythreads")
    setSelectedBody("Constantly AWOL. Grinds for a week before going off the grid again. Likes posting threads and hosting spaces. I'M MOBILEEE.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/bennythreads")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheTinkerer(){
    setSelectedMaxi(theTinkerer)
    setSelectedTitle("The Tinkerer")
    setSelectedSubtitle("Jimmeh")
    setSelectedBody("Always lurking. Probably still online at 6am. Bri'ish. In his schizo arc. The developer behind Money Maxis.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/jimmehjpg")
    setMaxiWebsite("http://cymbal.xyz")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheApe(){
    setSelectedMaxi(theApe)
    setSelectedTitle("The Ape")
    setSelectedSubtitle("n8s")
    setSelectedBody("Has a Moonbird but doesn't brag about it. We found him during an AVAX pilgrimage to AVAX Apes. Asian gang. Moderatooooor.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/n8gmi")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheCompanion(){
    setSelectedMaxi(theCompanion)
    setSelectedTitle("The Companion")
    setSelectedSubtitle("cwoo")
    setSelectedBody("Horniest guy in Money Maxi DAO. Starts wildin' out of nowhere. Enjoys pasting the same message 100 times. Kekamid. MTX Sensei.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/KekamidMaxi")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheWolf(){
    setSelectedMaxi(theWolf)
    setSelectedTitle("The Wolf")
    setSelectedSubtitle("PaperRouteFrank")
    setSelectedBody("The most lowkey Frank in Solana. Artist behind Money Maxis. Animates your craziest ideas on request without any hesitation.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/LLFlippa")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheShark(){
    setSelectedMaxi(theShark)
    setSelectedTitle("The Shark")
    setSelectedSubtitle("Phatique")
    setSelectedBody("Literally a boomer. Uses exclamation marks unironically. Secretly a homie when he's not writing deez nuts jokes in lounge.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/Phatiquez")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheFlash(){
    setSelectedMaxi(theFlash)
    setSelectedTitle("The Flash")
    setSelectedSubtitle("TreyLicious")
    setSelectedBody("Does everything in his power to dunk on Alex. Also tends to bully children online during his spare time.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/_tzilla_")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheWizard(){
    setSelectedMaxi(theWizard)
    setSelectedTitle("The Wizard")
    setSelectedSubtitle("Riley")
    setSelectedBody("Freakishly tall. Even taller with timbs. Making good progress on the recovery from his League addiction.")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/Sol_Snipes")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheImpaler(){
    setSelectedMaxi(theImpaler)
    setSelectedTitle("The Impaler")
    setSelectedSubtitle("Joe")
    setSelectedBody("Stuck working 18 hours per day. Spends time with his family like a normal person instead of living in web3. ")
    setFounder(true)
    setMaxiTwitter("https://twitter.com/J_crypto_R")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheProtector(){
    setSelectedMaxi(theProtector)
    setSelectedTitle("The Protector")
    setSelectedSubtitle("Easiness")
    setSelectedBody("Acts way older than he actually is. Has good taste in cat emojis. Once sent $12,000 without any collateral.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/easinesss")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheMobster(){
    setSelectedMaxi(theMobster)
    setSelectedTitle("The Mobster")
    setSelectedSubtitle("SOLdust")
    setSelectedBody("Has a dedicated :woe: emoji. Posts good wojak memes. Drinks soy milk and trades crypto. Also flips NEAR NFTs.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/algorithmpsycho")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheBlockchain(){
    setSelectedMaxi(theBlockchain)
    setSelectedTitle("The Blockchain")
    setSelectedSubtitle("Nikolaī")
    setSelectedBody("Has a Russian name but can't slav squat. Makes big money in NFTs and offloads his Computer Science coursework to us.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/NFTNikolai")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheDragon(){
    setSelectedMaxi(theDragon)
    setSelectedTitle("The Dragon")
    setSelectedSubtitle("Citriq")
    setSelectedBody("Lives in LA so must be rich. Jk, he's a Money Maxi, so of course he's rich. OG Maxi who's always around in the lounge.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/0xCitriq")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheCapitalist(){
    setSelectedMaxi(theCapitalist)
    setSelectedTitle("The Capitalist")
    setSelectedSubtitle("Jpeg Maxi")
    setSelectedBody("Co-founder & Head of Operations at Rooniverse. King of the Roos. Crypto OG and former online poker pro.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/jpegmaximalist")
    setMaxiWebsite("https://playrooniverse.com")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheOfficer(){
    setSelectedMaxi(theOfficer)
    setSelectedTitle("The Officer")
    setSelectedSubtitle("Dio")
    setSelectedBody("Self proclaimed '85 year old NFT trader' when in fact he isn't 85. Has whored his way through 3 different Money Maxis.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/USDCmaxi")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheLeprechaun(){
    setSelectedMaxi(theLeprechaun)
    setSelectedTitle("The Leprechaun")
    setSelectedSubtitle("DaddyDiamonds69")
    setSelectedBody("ETH guy. Owns an insane sneaker collection, amongst other things. Some say Alex still hasn't accepted his friend request.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/DaddyDiamonds66")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheMonster(){
    setSelectedMaxi(theMonster)
    setSelectedTitle("The Monster")
    setSelectedSubtitle("Kyle13")
    setSelectedBody("Our first Korean member. Originates from Korean Hustler DAO. Turned 1 SOL into 3000 SOL, BUT HE'S STILL HUNGRYYYYYYY.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/Hi_Kyle13")
    setMaxiWebsite("https://twitter.com/Koreanhustlers")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheJoker(){
    setSelectedMaxi(theJoker)
    setSelectedTitle("The Joker")
    setSelectedSubtitle("Melty")
    setSelectedBody("Creative Direction at Rooniverse. Queen of the Roos. Tipping the gender ratio in Money Maxi DAO by 1%.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/meltytanti")
    setMaxiWebsite("https://playrooniverse.com")
    if (!isMobile){
      playClick()
    }
  }

  function selectThePirate(){
    setSelectedMaxi(thePirate)
    setSelectedTitle("The Pirate")
    setSelectedSubtitle("Troyy68")
    setSelectedBody("Started trading NFTs because of money, stayed because of money. Always polite. A true Money Maxi. Recovering from paperhand syndrome.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/Troyy68")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheEngineer(){
    setSelectedMaxi(theEngineer)
    setSelectedTitle("The Engineer")
    setSelectedSubtitle("jf")
    setSelectedBody("Extremely lowkey. SOL maxi only. CEO of holding things to zero. Provides alpha when he's not lurking.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/jf_sol")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheReaper(){
    setSelectedMaxi(theReaper)
    setSelectedTitle("The Reaper")
    setSelectedSubtitle("thawty")
    setSelectedBody("Cracked at degen minting. Somehow manages to mint half the supply while in League matches. Quote: 'has the best maxi to date'.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/thawtyNFT")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheSanitizer(){
    setSelectedMaxi(theSanitizer)
    setSelectedTitle("The Sanitizer")
    setSelectedSubtitle("MavenPie")
    setSelectedBody("An Asian whale that'll sweep any floor as long as it's less than 0.1 SOL. He's Maven Maxis. We're all Maven Maxis.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/mavenpie")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheGenie(){
    setSelectedMaxi(theGenie)
    setSelectedTitle("The Genie")
    setSelectedSubtitle("Money Maxi DAO")
    setSelectedBody("Locked up in the vault for future use. Drop us a DM if you'd like to purchase it over-the-counter! Price negotiable.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/bm_drizzy")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheBunny(){
    setSelectedMaxi(theBunny)
    setSelectedTitle("The Bunny")
    setSelectedSubtitle("Lavaboi")
    setSelectedBody("Literally gives off the best vibes ever. Makes sense since his Maxi is a cute rabbit. More of a lurker, but joins in on the fun when possible.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/Cryptonftice")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }
  
  function selectTheRasta(){
    setSelectedMaxi(theRasta)
    setSelectedTitle("The Rasta")
    setSelectedSubtitle("tropiCool")
    setSelectedBody("Sniped his Maxi from Tuiuiu. Always brings great vibes to the chat. Makes us happy to have him as part of the Maxi family.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/tropi_cool")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheRestaurateur(){
    setSelectedMaxi(theRestaurateur)
    setSelectedTitle("The Restaurateur")
    setSelectedSubtitle("Tuiuiu")
    setSelectedBody("Grabbed a Maxi after getting his previous bids rugged by tropiCool. Pastes paragraphs of great alpha to our chat. Has a CrypToad.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/TheTuiuiu")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheSpendthrift(){
    setSelectedMaxi(theSpendthrift)
    setSelectedTitle("The Spendthrift")
    setSelectedSubtitle("thebob")
    setSelectedBody("Fellow art enjoyoooor. Jpegs made him fall in love with crypto. Also part of other 1/1 DAOs, such as CrypticDAO and Chimpions.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/thebob_nft")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheAstronaut(){
    setSelectedMaxi(theAstronaut)
    setSelectedTitle("The Astronaut")
    setSelectedSubtitle("Arby")
    setSelectedBody("The animated rainbow puke Boryoku Dragon that you always see on Twitter. Very chill, will always say gm to the gang.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/arby_sol")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheButcher(){
    setSelectedMaxi(theButcher)
    setSelectedTitle("The Butcher")
    setSelectedSubtitle("pboi")
    setSelectedBody("Professional on chain clown. Retired defi ponzi connoisseur. Wasn’t quick enough to grab a chair when the music stopped, now stuck here forever.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/0xpboi")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheMiner(){
    setSelectedMaxi(theMiner)
    setSelectedTitle("The Miner")
    setSelectedSubtitle("guccicrypto")
    setSelectedBody("Has gucci in his name, so you know he's upmarket. Been in SOL NFTs for a while, so we always welcome his insight.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/CryptoGucci5")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheChef(){
    setSelectedMaxi(theChef)
    setSelectedTitle("The Chef")
    setSelectedSubtitle("OnlyChads")
    setSelectedBody(" A chad IRL. Like the 5th person to own The Chef maxi. Masterfully combines brewery and 1/1 NFTs. Maxi brew wen?")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/0nlyChads")
    setMaxiWebsite("https://exchange.art/artists/OnlyChads/nfts")
    if (!isMobile){
      playClick()
    }
  }

  function selectThePimp(){
    setSelectedMaxi(thePimp)
    setSelectedTitle("The Pimp")
    setSelectedSubtitle("WP")
    setSelectedBody("Has @whitepaper on Twitter because he was trying to price-gouge a startup. Sickest username ever. Also in CrypticDAO.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/whitepaper")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheChemist(){
    setSelectedMaxi(theChemist)
    setSelectedTitle("The Chemist")
    setSelectedSubtitle("NaClZ")
    setSelectedBody("Advisor for Secret Skellies Society. Another NEAR maxi. Aped in after Anon and GMA won their auctions. SSS infiltration?")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/naclzsol")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheBear(){
    setSelectedMaxi(theBear)
    setSelectedTitle("The Bear")
    setSelectedSubtitle("mintmantemope")
    setSelectedBody("Writes intelligent threads unlike Benny. Told us he's trying to be more assertive, says fuck you instead of gm.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/MMMNFT")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheSurfer(){
    setSelectedMaxi(theSurfer)
    setSelectedTitle("The Surfer")
    setSelectedSubtitle("ballsagna")
    setSelectedBody("Is blonde and likes surfing, managed to get Troyy to swap for The Surfer. Talented artist and smart investor.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/HabitualHijinx")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheGolfer(){
    setSelectedMaxi(theGolfer)
    setSelectedTitle("The Golfer")
    setSelectedSubtitle("Money Maxi DAO")
    setSelectedBody("Locked up in the vault for future use. Drop us a DM if you'd like to purchase it over-the-counter! Price negotiable.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/Spud_Lite")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheFisherman(){
    setSelectedMaxi(theFisherman)
    setSelectedTitle("The Fisherman")
    setSelectedSubtitle("huro")
    setSelectedBody("Another bright, younger homie in Money Maxis. Makes us feel like boomers. Well-versed in bots and underground services.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/huroNFT")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheStriker(){
    setSelectedMaxi(theStriker)
    setSelectedTitle("The Striker")
    setSelectedSubtitle("sallfybaby")
    setSelectedBody("Rescued from AVAX by n8s - that's the butterfly effect in motion. Also holds AVAX Apes. Currently in the process of making it on SOL.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/sallyfbabyy")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectThePainter(){
    setSelectedMaxi(thePainter)
    setSelectedTitle("The Painter")
    setSelectedSubtitle("runtz")
    setSelectedBody("Constantly apeing 1/1 art. Developer for SolSers - another beautiful 1/1 collection. Always brings the positive vibes.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/runtzcrypto")
    setMaxiWebsite("https://twitter.com/SolSerNFT")
    if (!isMobile){
      playClick()
    }
  }

  function selectThePlumber(){
    setSelectedMaxi(thePlumber)
    setSelectedTitle("The Plumber")
    setSelectedSubtitle("Mooje")
    setSelectedBody("Weed and NFT degen. Lurking in the shadows behind his Mario pfp. Probably a sick guy considering only sick guys join Money Maxis.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/moojesol")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheNinja(){
    setSelectedMaxi(theNinja)
    setSelectedTitle("The Ninja")
    setSelectedSubtitle("rabbid")
    setSelectedBody("Got into NFTs through DeGods. Has literally NEVER been rugged on SOL - clearly built different.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/rabbidsol")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheAucioneer(){
    setSelectedMaxi(theAuctioneer)
    setSelectedTitle("The Auctioneer")
    setSelectedSubtitle("BonjourKim_")
    setSelectedBody("Another Korean homie. Probably hates us for giving him the @korean tag and pinging it. Listed his Maxi for a day.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/BonjourKim_")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectThePeanut(){
    setSelectedMaxi(thePeanut)
    setSelectedTitle("The Peanut")
    setSelectedSubtitle("GRIEVANCE.S◎L")
    setSelectedBody("Justin. Also owns a CyberKong. Is a Dubstep DJ with certified banger riddim mixes on his Soundcloud.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/IAmGRIEVANCE")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheHooper(){
    setSelectedMaxi(theHooper)
    setSelectedTitle("The Hooper")
    setSelectedSubtitle("EasyEats")
    setSelectedBody("The Sol Guy on @niftyportal. Lost it all during the Tezos ICO in 2017. Legally adopted Benny at NFT NYC.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/EasyEatsBodega")
    setMaxiWebsite("https://twitter.com/niftyportal")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheTurtle(){
    setSelectedMaxi(theTurtle)
    setSelectedTitle("The Turtle")
    setSelectedSubtitle("andreew")
    setSelectedBody("Paid 40 SOL to enter shitpost chat AKA Money Maxis (secretly loves it though). Bearish on any web3 game.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/andreewuu")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheStatue(){
    setSelectedMaxi(theStatue)
    setSelectedTitle("The Statue")
    setSelectedSubtitle("danielwagmi")
    setSelectedBody("Terrorizes normies on Twitter. Loves dunking on Degen Apes. Serial gambler. Will suck dick for $5K.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/danielwagmi")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheRedditor(){
    setSelectedMaxi(theRedditor)
    setSelectedTitle("The Redditor")
    setSelectedSubtitle("xRedditor")
    setSelectedBody("A mystery man. Made new Twitter and Discord accounts before he joined us. Supposedely Aussie. BUT WHO IS HE?")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/xRedditor")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheFrog(){
    setSelectedMaxi(theFrog)
    setSelectedTitle("The Frog")
    setSelectedSubtitle("???")
    setSelectedBody("Another hidden identity. This guy is a total anon who bought straight from a fresh, CEX-funded wallet. 0 trace of this guy. WHO ARE YOU, MR. FROG?")
    setFounder(false)
    setMaxiTwitter(null)
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheRanger(){
    setSelectedMaxi(theRanger)
    setSelectedTitle("The Ranger")
    setSelectedSubtitle("Angelia / RooIntern")
    setSelectedBody("Our third member of the Rooniverse, ANGELIA!! Famous for her cute stickers and fat jambs. Tipping the gender ratio by another 1%.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/RooIntern")
    setMaxiWebsite("https://playrooniverse.com")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheMinion(){
    setSelectedMaxi(theMinion)
    setSelectedTitle("The Minion")
    setSelectedSubtitle("wassupvegas")
    setSelectedBody("9-5 hard-working minion. Buying high and selling low. Relatively new to the NFT space, so it's fun to get his perspective on things.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/Mrcapitalv")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheGojira(){
    setSelectedMaxi(theGojira)
    setSelectedTitle("The Gojira")
    setSelectedSubtitle("wgmidiot")
    setSelectedBody("Founder of the Ghostface NFT collection. Helpful and humble. Can be found shitposting with the gang. Swapped Maxis with WP.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/wgmidiot")
    setMaxiWebsite("https://www.ghostfacelabs.com/")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheSponge(){
    setSelectedMaxi(theSponge)
    setSelectedTitle("The Sponge")
    setSelectedSubtitle("Jango")
    setSelectedBody("Co-founder of Shy Ones. Absolutely god-tier artist. Lovely fella. From Turkey, reppin' the Euro crew. In his fuckboy arc.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/janbrek")
    setMaxiWebsite("https://twitter.com/ShyOnesNFT")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheNotorious(){
    setSelectedMaxi(theNotorious)
    setSelectedTitle("The Notorious")
    setSelectedSubtitle("Tominator77")
    setSelectedBody("Creator of The Degen DAO. Fired MTX for clowning around in Alex's Twitter spaces. An OG snek homie. 'Hold sol = millionaire'.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/shamallama77")
    setMaxiWebsite("https://twitter.com/TheDegenDAO")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheHacker(){
    setSelectedMaxi(theHacker)
    setSelectedTitle("The Hacker")
    setSelectedSubtitle("Anon666")
    setSelectedBody("Co-founder of Secret Skellies Society. NEAR aficionado. Good friends with Alex. Got the Maxi that matches his name perfectly.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/Anon666NFT")
    setMaxiWebsite("https://secretskelliessociety.com/")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheBrain(){
    setSelectedMaxi(theBrain)
    setSelectedTitle("The Brain")
    setSelectedSubtitle("GMA")
    setSelectedBody("Co-founder of Secret Skellies Society. Web3 Branding/UX/UI Designer. Already has a skeleton pfp so fits right in. UK gang.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/GMA_NFT")
    setMaxiWebsite("https://secretskelliessociety.com/")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheFrozen(){
    setSelectedMaxi(theFrozen)
    setSelectedTitle("The Frozen")
    setSelectedSubtitle("thawty")
    setSelectedBody("Thawty back again with another maxi. Decided he wanted to DCA his position LMAO. Thanks brother, hope it pays off.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/thawtyNFT")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheSaiyan(){
    setSelectedMaxi(theSaiyan)
    setSelectedTitle("The Saiyan")
    setSelectedSubtitle("curry30")
    setSelectedBody("THE MFING MAN, CURRY. Better than the real Steph Curry. Battled it out in Visitors chat for months before robbing someone blind.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/StephenCurry377")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheWaiter(){
    setSelectedMaxi(theWaiter)
    setSelectedTitle("The Waiter")
    setSelectedSubtitle("NamiSolami")
    setSelectedBody("Finally bought a Maxi at the absolute bottom after lurking in the visitors channel for 6 months. Welcome, Mr Solami.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/naclzsol")
    setMaxiWebsite(null)
    if (!isMobile){
      playClick()
    }
  }

  function selectTheBatman(){
    setSelectedMaxi(theBatman)
    setSelectedTitle("The Batman")
    setSelectedSubtitle("ChartFuMonkey")
    setSelectedBody("The legend himself, ChartFu. Chief Degen Officer at Magic Eden. Capitulated  after watching us auction on ME for 3 months. Beautiful accent.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/ChartFuMonkey")
    setMaxiWebsite("https://magiceden.io")
    if (!isMobile){
      playClick()
    }
  }

  function selectTheCowboy(){
    setSelectedMaxi(theCowboy)
    setSelectedTitle("The Cowboy")
    setSelectedSubtitle("ge0")
    setSelectedBody("One of the masterminds & mayors behind Cowboy DAO. Can confirm he has a big brain, as suggested by his @. Part of the UK gang.")
    setFounder(false)
    setMaxiTwitter("https://twitter.com/GEOBIGBRAINN")
    setMaxiWebsite("https://cryptocowboy.country/")
    if (!isMobile){
      playClick()
    }
  }


  return (
    <>
      <ShitposterTitle src={shitposter} alt="Money Maxi Logo" draggable="false"/>
      <BioContainer>
        <BioImage src={selectedMaxi} draggable="false"/>
        <FlexContainer>
          <BioTitle>{selectedTitle}</BioTitle>
          <BioSubtitle>
            {selectedSubtitle}
            { isFounder 
                ? <Badge>Founder</Badge> 
                : <MaxiBadge>Money Maxi</MaxiBadge>
            }
          </BioSubtitle>
          <BioBody>{selectedBody}</BioBody>
          <FlexRight>
            { maxiWebsite 
                ? <><SocialContainer href={maxiWebsite} target="blank_" title="Website">
                    <ExternalLink>
                      <svg width="14" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6156 4.30961C12.6156 4.30961 12.6156 4.30961 12.6156 4.27702C12.1567 3.0229 11.3241 1.94013 10.2303 1.17518C9.13648 0.410223 7.83434 0 6.5 0C5.16566 0 3.86353 0.410223 2.76973 1.17518C1.67593 1.94013 0.843265 3.0229 0.384364 4.27702C0.384364 4.27702 0.384364 4.27702 0.384364 4.30961C-0.128121 5.72482 -0.128121 7.27518 0.384364 8.69039C0.384364 8.69039 0.384364 8.69039 0.384364 8.72298C0.843265 9.9771 1.67593 11.0599 2.76973 11.8248C3.86353 12.5898 5.16566 13 6.5 13C7.83434 13 9.13648 12.5898 10.2303 11.8248C11.3241 11.0599 12.1567 9.9771 12.6156 8.72298C12.6156 8.72298 12.6156 8.72298 12.6156 8.69039C13.1281 7.27518 13.1281 5.72482 12.6156 4.30961V4.30961ZM1.44597 7.8038C1.22012 6.9493 1.22012 6.0507 1.44597 5.1962H2.65737C2.55319 6.06227 2.55319 6.93773 2.65737 7.8038H1.44597ZM1.98003 9.10761H2.89184C3.04471 9.689 3.26322 10.2511 3.54313 10.783C2.90441 10.3475 2.37048 9.77519 1.98003 9.10761ZM2.89184 3.89239H1.98003C2.36486 3.22686 2.89197 2.65474 3.52359 2.21701C3.25041 2.74977 3.03847 3.31182 2.89184 3.89239V3.89239ZM5.83568 11.5196C5.03574 10.9321 4.47393 10.0758 4.25304 9.10761H5.83568V11.5196ZM5.83568 7.8038H3.97299C3.85145 6.93886 3.85145 6.06114 3.97299 5.1962H5.83568V7.8038ZM5.83568 3.89239H4.25304C4.47393 2.92417 5.03574 2.06793 5.83568 1.48036V3.89239ZM10.9939 3.89239H10.0821C9.92924 3.311 9.71073 2.74892 9.43082 2.21701C10.0695 2.65252 10.6035 3.22481 10.9939 3.89239ZM7.13827 1.48036C7.93821 2.06793 8.50002 2.92417 8.72091 3.89239H7.13827V1.48036ZM7.13827 11.5196V9.10761H8.72091C8.50002 10.0758 7.93821 10.9321 7.13827 11.5196V11.5196ZM9.00096 7.8038H7.13827V5.1962H9.00096C9.1225 6.06114 9.1225 6.93886 9.00096 7.8038ZM9.45035 10.783C9.73027 10.2511 9.94878 9.689 10.1016 9.10761H11.0135C10.623 9.77519 10.0891 10.3475 9.45035 10.783ZM11.528 7.8038H10.3166C10.3695 7.37124 10.3956 6.9358 10.3947 6.5C10.3954 6.0642 10.3693 5.62878 10.3166 5.1962H11.528C11.7538 6.0507 11.7538 6.9493 11.528 7.8038Z"/>
                      </svg>
                    </ExternalLink>
                  </SocialContainer>
                  <SpanGap></SpanGap></>
                : null
            }
            { maxiTwitter
                ? <><SocialContainer href={maxiTwitter} target="blank_" title="Twitter">
                  <ExternalLink>
                    <svg width="15" viewBox="0 0 15 13" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.71189 12.1969C10.3721 12.1969 13.4694 7.503 13.4694 3.43938C13.4694 3.30732 13.4694 3.17527 13.4634 3.04322C14.0636 2.61105 14.5858 2.06483 15 1.44658C14.4478 1.69268 13.8535 1.85474 13.2293 1.93277C13.8655 1.55462 14.3517 0.948379 14.5858 0.228091C13.9916 0.582233 13.3313 0.834334 12.6291 0.972389C12.0648 0.372149 11.2665 0 10.3842 0C8.68547 0 7.30492 1.38055 7.30492 3.07923C7.30492 3.31933 7.33493 3.55342 7.38295 3.78151C4.82593 3.65546 2.55702 2.42497 1.03842 0.564226C0.77431 1.02041 0.62425 1.54862 0.62425 2.11285C0.62425 3.18127 1.17047 4.12365 1.9928 4.67587C1.4886 4.65786 1.01441 4.51981 0.60024 4.29172C0.60024 4.30372 0.60024 4.31573 0.60024 4.33373C0.60024 5.82233 1.66267 7.07083 3.06723 7.35294C2.80912 7.42497 2.53902 7.46099 2.2569 7.46099C2.05882 7.46099 1.86675 7.44298 1.68067 7.40696C2.07083 8.63145 3.21128 9.51981 4.55582 9.54382C3.4994 10.3722 2.17287 10.8643 0.732293 10.8643C0.486195 10.8643 0.240096 10.8523 0 10.8223C1.35654 11.6867 2.97719 12.1969 4.71189 12.1969Z"/>
                    </svg>
                  </ExternalLink>
                </SocialContainer></>
                : null
            }
          </FlexRight>
        </FlexContainer>
      </BioContainer>
      <ImageGrid>
        {/* <MaxiImage src={theKingStill} onClick={() => selectTheKing()} draggable="false"/>
        <MaxiImage src={thePrinceStill} onClick={() => selectThePrince()} draggable="false"/> */}
        <MaxiImage src={theTinkererStill} onClick={() => selectTheTinkerer()} draggable="false"/>
        <MaxiImage src={theApeStill} onClick={() => selectTheApe()} draggable="false"/>
        <MaxiImage src={theCompanionStill} onClick={() => selectTheCompanion()} draggable="false"/>
        <MaxiImage src={theWolfStill} onClick={() => selectTheWolf()} draggable="false"/>
        <MaxiImage src={theSharkStill} onClick={() => selectTheShark()} draggable="false"/>
        <MaxiImage src={theFlashStill} onClick={() => selectTheFlash()} draggable="false"/>
        <MaxiImage src={theWizardStill} onClick={() => selectTheWizard()} draggable="false"/>
        <MaxiImage src={theImpalerStill} onClick={() => selectTheImpaler()} draggable="false"/>
        <MaxiImage src={theProtectorStill} onClick={() => selectTheProtector()} draggable="false"/>
        <MaxiImage src={theMobsterStill} onClick={() => selectTheMobster()} draggable="false"/>
        <MaxiImage src={theBlockchainStill} onClick={() => selectTheBlockchain()} draggable="false"/>
        <MaxiImage src={theDragonStill} onClick={() => selectTheDragon()} draggable="false"/>
        <MaxiImage src={theCapitalistStill} onClick={() => selectTheCapitalist()} draggable="false"/>
        <MaxiImage src={theOfficerStill} onClick={() => selectTheOfficer()} draggable="false"/>
        <MaxiImage src={theLeprechaunStill} onClick={() => selectTheLeprechaun()} draggable="false"/>
        <MaxiImage src={theMonsterStill} onClick={() => selectTheMonster()} draggable="false"/>
        <MaxiImage src={theJokerStill} onClick={() => selectTheJoker()} draggable="false"/>
        <MaxiImage src={thePirateStill} onClick={() => selectThePirate()} draggable="false"/>
        <MaxiImage src={theEngineerStill} onClick={() => selectTheEngineer()} draggable="false"/>
        <MaxiImage src={theReaperStill} onClick={() => selectTheReaper()} draggable="false"/>
        <MaxiImage src={theSanitizerStill} onClick={() => selectTheSanitizer()} draggable="false"/>
        <MaxiImage src={theGenieStill} onClick={() => selectTheGenie()} draggable="false"/>
        <MaxiImage src={theBunnyStill} onClick={() => selectTheBunny()} draggable="false"/>
        <MaxiImage src={theRastaStill} onClick={() => selectTheRasta()} draggable="false"/>
        <MaxiImage src={theRestaurateurStill} onClick={() => selectTheRestaurateur()} draggable="false"/>
        <MaxiImage src={theSpendthriftStill} onClick={() => selectTheSpendthrift()} draggable="false"/>
        <MaxiImage src={theAstronautStill} onClick={() => selectTheAstronaut()} draggable="false"/>
        <MaxiImage src={theButcherStill} onClick={() => selectTheButcher()} draggable="false"/>
        <MaxiImage src={theMinerStill} onClick={() => selectTheMiner()} draggable="false"/>
        <MaxiImage src={theChefStill} onClick={() => selectTheChef()} draggable="false"/>
        <MaxiImage src={thePimpStill} onClick={() => selectThePimp()} draggable="false"/>
        <MaxiImage src={theChemistStill} onClick={() => selectTheChemist()} draggable="false"/>
        <MaxiImage src={theBearStill} onClick={() => selectTheBear()} draggable="false"/>
        <MaxiImage src={theSurferStill} onClick={() => selectTheSurfer()} draggable="false"/>
        <MaxiImage src={theGolferStill} onClick={() => selectTheGolfer()} draggable="false"/>
        <MaxiImage src={theFishermanStill} onClick={() => selectTheFisherman()} draggable="false"/>
        <MaxiImage src={theStrikerStill} onClick={() => selectTheStriker()} draggable="false"/>
        <MaxiImage src={thePainterStill} onClick={() => selectThePainter()} draggable="false"/>
        <MaxiImage src={thePlumberStill} onClick={() => selectThePlumber()} draggable="false"/>
        <MaxiImage src={theNinjaStill} onClick={() => selectTheNinja()} draggable="false"/>
        <MaxiImage src={theAuctioneerStill} onClick={() => selectTheAucioneer()} draggable="false"/>
        <MaxiImage src={thePeanutStill} onClick={() => selectThePeanut()} draggable="false"/>
        <MaxiImage src={theHooperStill} onClick={() => selectTheHooper()} draggable="false"/>
        <MaxiImage src={theTurtleStill} onClick={() => selectTheTurtle()} draggable="false"/>
        <MaxiImage src={theStatueStill} onClick={() => selectTheStatue()} draggable="false"/>
        <MaxiImage src={theRedditorStill} onClick={() => selectTheRedditor()} draggable="false"/>
        <MaxiImage src={theFrogStill} onClick={() => selectTheFrog()} draggable="false"/>
        <MaxiImage src={theRangerStill} onClick={() => selectTheRanger()} draggable="false"/>
        <MaxiImage src={theMinionStill} onClick={() => selectTheMinion()} draggable="false"/>
        <MaxiImage src={theGojiraStill} onClick={() => selectTheGojira()} draggable="false"/>
        <MaxiImage src={theSpongeStill} onClick={() => selectTheSponge()} draggable="false"/>
        <MaxiImage src={theNotoriousStill} onClick={() => selectTheNotorious()} draggable="false"/>
        <MaxiImage src={theHackerStill} onClick={() => selectTheHacker()} draggable="false"/>
        <MaxiImage src={theBrainStill} onClick={() => selectTheBrain()} draggable="false"/>
        <MaxiImage src={theFrozenStill} onClick={() => selectTheFrozen()} draggable="false"/>
        <MaxiImage src={theSaiyanStill} onClick={() => selectTheSaiyan()} draggable="false"/>
        <MaxiImage src={theWaiterStill} onClick={() => selectTheWaiter()} draggable="false"/>
        <MaxiImage src={theBatmanStill} onClick={() => selectTheBatman()} draggable="false"/>
        <MaxiImage src={theCowboyStill} onClick={() => selectTheCowboy()} draggable="false"/>
      </ImageGrid>
      <FlexContainer>
      </FlexContainer>
    </>
  )
}

export default Members